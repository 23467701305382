import React, { useState } from 'react';

import withLayout from '../components/withLayout';
import Heading from '../components/molecules/Heading';
import { FaFileDownload, FaCheck, FaSpinner } from 'react-icons/all';
import styled, { keyframes } from 'styled-components';
import breakpoints from '../utils/breakpoints';
import { containerStyles } from '../utils/styles';
import fonts from '../utils/fonts';
import { FormattedMessage, useIntl } from 'react-intl';
import DownloadReportContent from '../components/images/DownloadReportContent';
import PopupType2 from '../components/organisms/PopupType2';
import { darken } from 'polished';
import axios from 'axios';
import qs from 'qs';
import { messages } from '../i18n/translations';
import Section from '../components/atoms/Section';

const isIE = () => window.document.documentMode != null;

const sendOnIe = (href, fileName) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', href);
  xhr.responseType = 'blob';
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== 4) {
      return;
    }

    if (xhr.status === 200) {
      window.navigator.msSaveBlob(xhr.response, fileName);
    }
  };

  xhr.send();
};

const Container = styled.div`
  ${containerStyles};

  @media ${breakpoints.lg} {
    margin: 0 auto;
  }
`;

const Grid = styled.div`
  @media ${breakpoints.lg} {
    display: grid;
    grid-column-gap: 1.5625rem;
    grid-template-columns: 45% 55%;
    grid-template-areas:
      'main'
      'image';
  }
`;

const StyledSection = styled(Section)`
  position: relative;
`;

const ImageSection = styled.div`
  display: none;

  @media ${breakpoints.lg} {
    display: block;
  }
`;

const MainSection = styled.div`
  grid-area: main;
  overflow: hidden;
`;

const Button = styled.button`
  align-items: center;
  background: #e42229;
  border: 0;
  border-radius: 0.4375rem 0 0.4375rem 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: ${fonts.barlowCondensed};
  font-size: 1rem;
  font-weight: 700;
  height: 2.625rem;
  line-height: 0.9375rem;
  padding: 0 0.9375rem 0 1.125rem;

  @media ${breakpoints.lg} {
    font-size: 1.5rem;
    height: 3.75rem;
    line-height: 1.375rem;
    padding: 0 1.25rem 0 1.625rem;
  }

  &:hover {
    background: ${darken(0.1, '#E42229')};
  }
`;

const ButtonContainer = styled.div`
  margin-top: 3.75rem;
  padding-left: 3.125rem;
`;

const ButtonIcon = styled(FaFileDownload)`
  font-size 1.125rem;
  margin-left: 1.25rem;

  @media ${breakpoints.lg} {
    font-size: 1.375rem;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListIcon = styled.span`
  color: #6bbf28;
  font-size: 1.875rem;
  flex-shrink: 0;
  margin-right: 1rem;
`;

const ListItem = styled.li`
  color: #000010;
  font-size: 1.0625rem;
  line-height: 1.5625rem;
  list-style-type: none;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
`;

const Text1 = styled.div`
  color: #004a99;
  font-family: ${fonts.pko};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 3.75rem;

  @media ${breakpoints.lg} {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }

  &::after {
    background-image: linear-gradient(
      to right,
      #d9d9d9 0%,
      rgba(238, 238, 238, 0) 100%
    );
    content: '';
    display: block;
    height: 0.125rem;
    margin-top: 1.75rem;
  }
`;

const Text2 = styled.div`
  color: #000010;
  font-family: ${fonts.pko};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;

  @media ${breakpoints.lg} {
    font-size: 1.875rem;
    line-height: 2.125rem;
  }
`;

const PopupButton = styled.button`
  background: ${props => (props.active ? '#FFC331' : '#EDEDED')};
  border: 0;
  border-radius: 0.625rem;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  padding: 0.5rem 0.75rem 0.5rem;

  @media ${breakpoints.lg} {
    font-size: 1.3125rem;
    line-height: 1.5625rem;
    padding: 0.6875rem 0.9375rem 0.875rem;
  }

  &:hover {
    background: ${props => (props.active ? darken(0.1, '#FFC331') : '#CBCCCB')};
  }
`;

const PopupButtonWrapper = styled.div`
  padding: 0.4375rem;

  @media ${breakpoints.lg} {
    padding: 0.625rem;
  }
`;

const PopupButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.4375rem;

  @media ${breakpoints.lg} {
    margin: -0.625rem;
  }
`;

const PopupSeparator = styled.div`
  background: linear-gradient(to right, #326dad 0, rgba(255, 255, 255, 0) 100%);
  height: 0.0625rem;
  margin: 1.875rem 0;
`;

const PopupBottom = styled.div``;

const PopupSubmit = styled.button`
  align-items: center;
  background: #e42229;
  border: 0;
  border-radius: 0.625rem 0 0.625rem 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: ${fonts.barlowCondensed};
  font-size: 1rem;
  font-weight: 700;
  height: 2.625rem;
  line-height: 0.9375rem;
  outline: none;
  padding: 0 0.9375rem 0 1.125rem;

  @media ${breakpoints.lg} {
    font-size: 1.5rem;
    height: 3.75rem;
    line-height: 1.375rem;
    padding: 0 1.25rem 0 1.5625rem;
  }

  &[disabled] {
    background: #cbcccb;
    cursor: default;
  }

  &:not([disabled]):hover {
    background: ${darken(0.1, '#E42229')};
  }
`;

const PopupSubmitIcon = styled(FaFileDownload)`
  font-size: 1.375rem;
  margin-left: 1.25rem;
`;

const DownloadReport = () => {
  const [dialogProps, setDialogProps] = useState(null);
  const [selectedButtons, setSelectedButtons] = useState([]);

  const intl = useIntl();

  const downloadFile = () => {
    const file = `/assets/${intl.locale}/StartUpTheBank.pdf`;
    const fileName = 'StartUpTheBank.pdf';
    if (isIE()) {
      sendOnIe(file, fileName);
      return;
    }

    const save = document.createElement('a');
    save.href = file;
    save.target = '_blank';
    save.download = fileName;

    const evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(evt);

    (window.URL || window.webkitURL).revokeObjectURL(save.href);
  };

  const handlePopupButtonClick = button => {
    if (selectedButtons.includes(button)) {
      setSelectedButtons(
        selectedButtons.filter(selected => selected !== button)
      );
      return;
    }

    setSelectedButtons([button]);
  };

  const submit = () => {
    window.gtag('event', 'fill_report_survey', {
      event_label: `fill_report_survey_${selectedButtons[0]}`,
    });
    setDialogProps(null);
    setSelectedButtons([]);
    downloadFile();
  };

  return (
    <>
      {dialogProps && (
        <PopupType2 close={() => setDialogProps(null)} {...dialogProps}>
          <PopupSeparator />

          <PopupButtons>
            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('startup')}
                onClick={() => handlePopupButtonClick('startup')}
              >
                <FormattedMessage id="downloadreport.popup.startup" />
              </PopupButton>
            </PopupButtonWrapper>

            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('bank')}
                onClick={() => handlePopupButtonClick('bank')}
              >
                <FormattedMessage id="downloadreport.popup.bank" />
              </PopupButton>
            </PopupButtonWrapper>

            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('korporacja')}
                onClick={() => handlePopupButtonClick('korporacja')}
              >
                <FormattedMessage id="downloadreport.popup.korporacja" />
              </PopupButton>
            </PopupButtonWrapper>

            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('msp')}
                onClick={() => handlePopupButtonClick('msp')}
              >
                <FormattedMessage id="downloadreport.popup.msp" />
              </PopupButton>
            </PopupButtonWrapper>

            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('inwestor')}
                onClick={() => handlePopupButtonClick('inwestor')}
              >
                <FormattedMessage id="downloadreport.popup.inwestor" />
              </PopupButton>
            </PopupButtonWrapper>

            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('ngo')}
                onClick={() => handlePopupButtonClick('ngo')}
              >
                <FormattedMessage id="downloadreport.popup.ngo" />
              </PopupButton>
            </PopupButtonWrapper>

            <PopupButtonWrapper>
              <PopupButton
                active={selectedButtons.includes('inne')}
                onClick={() => handlePopupButtonClick('inne')}
              >
                <FormattedMessage id="downloadreport.popup.inne" />
              </PopupButton>
            </PopupButtonWrapper>
          </PopupButtons>

          <PopupSeparator />

          <PopupBottom>
            <PopupSubmit
              disabled={selectedButtons.length === 0}
              onClick={submit}
            >
              <FormattedMessage id="downloadreport.popup.download" />
              <PopupSubmitIcon />
            </PopupSubmit>
          </PopupBottom>
        </PopupType2>
      )}

      <StyledSection>
        <Container>
          <Heading icon={<FaFileDownload />} title="downloadreport.heading" />

          <Grid>
            <MainSection>
              <Text1>
                <FormattedMessage id="downloadreport.text1" />
              </Text1>

              <Text2>
                <FormattedMessage id="downloadreport.text2" />
              </Text2>

              <List>
                <ListItem>
                  <ListIcon>
                    <FaCheck />
                  </ListIcon>
                  <FormattedMessage id="downloadreport.listItem1" />
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <FaCheck />
                  </ListIcon>
                  <FormattedMessage id="downloadreport.listItem2" />
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <FaCheck />
                  </ListIcon>
                  <FormattedMessage id="downloadreport.listItem3" />
                </ListItem>
              </List>

              <ButtonContainer>
                <Button
                  onClick={() => {
                    setDialogProps({
                      header: messages[intl.locale].downloadreport.popup.header,
                      width: 520,
                    });
                    window.gtag('event', 'click_button', {
                      event_label: 'click_button_download_report',
                    });
                  }}
                >
                  <FormattedMessage id="downloadreport.button" />
                  <ButtonIcon />
                </Button>
              </ButtonContainer>
            </MainSection>

            <ImageSection>
              <DownloadReportContent />
            </ImageSection>
          </Grid>
        </Container>
      </StyledSection>
    </>
  );
};

export default withLayout(DownloadReport);
